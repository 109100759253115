import clsx from 'clsx'
import { Button, ButtonModeProp } from '../Button'

//@todo Add generic
type Props = {
    value: string,
    onChange: (value: string) => void,
    options: {
        id: string,
        label: string,
    }[],
    className?: string,
    mode?: ButtonModeProp,
}

export const SwitchButtons = ({options, value, onChange, className, mode = 'dark'}: Props) => {
    return (
        <div className={clsx('flex grid--4', className)}>
            {options.map((option) => (
                <Button
                    key={option.id}
                    mode={mode}
                    isActive={option.id === value}
                    onClick={() => onChange(option.id)}
                >
                    {option.label}
                </Button>
            ))}
        </div>
    )
}