import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from '../../store'

const sliceUI = createSlice({
    name: 'ui',
    initialState: {
        showSearchBlur: false,
    },
    reducers: {
        setSearchBlur: (state, { payload }: PayloadAction<boolean>) => {
            state.showSearchBlur = payload
        },
    },
})

export const {
    setSearchBlur,
} = sliceUI.actions

export const selectShowSearchBlur = (state: RootState) => state.ui.showSearchBlur

export const {reducer: reducerUI} =  sliceUI