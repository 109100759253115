import clsx from 'clsx'
import { FU } from '../types'
import st from './Button.module.scss'

export type ButtonModeProp = 'primary' | 'stroke' | 'dark'

type Props = {
    className?: string,
    mode?: ButtonModeProp,
    isActive?: boolean,
    disabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    badge?: string | number,
}

export const Button: FU<Props> = ({
    children,
    className,
    mode,
    isActive,
    disabled,
    onClick,
    badge,
}) => {
    return (
        <button
            className={clsx(
                st.button,
                mode && st[mode],
                isActive && st.isActive,
                className,
            )}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
            {badge && (
                <span className={st.badge}>
                    {badge}
                </span>
            )}
        </button>
    )
}