import arrow_down from '../../public/img/arrow_down.svg'
import user_login from '../../public/img/user_login.svg'

import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import LanguageSwitcher from '../LanguageSwitcher'
import { useRef } from 'react'
import Dropdown from '../Dropdown'
import MenuMobile from '../MobileMenu'
import Link from 'next/link'

import { setAuthModal } from '../../features/app'
import { useAppDispatch, useAppSelector } from '../../hooks'
import NoSSR from '../NoSsr'

import user_ico from '../../public/img/user.svg'
import settings from '../../public/img/settings.svg'
import logout from '../../public/img/log-out.svg'
import { postLogout } from '../../features/auth'
import { HeaderCompetitionLinks } from './HeaderCompetitionLinks'
import st from './Header.module.scss'
import { CompetitionTop } from '../../api'
import { APP_ROUTES } from '../../const'
import { Search } from '../../features-ui'

type Props = {
    competitionsTop: CompetitionTop[],
}

export default function Header({ competitionsTop }: Props) {
    const { t } = useTranslation('common')
    const dispatch = useAppDispatch()
    const refMainContainer = useRef<HTMLDivElement>(null)
    const AVATAR_COLOR = [
        'e17076',
        'a695e7',
        '7bc862',
        'ee7aae',
        '65aadd',
        'faa774',
        '6ec9cb',
        '6e8acb',
        'cbca6e',
        'b86ecb',
    ]
    const user = useAppSelector((state) => state.auth.user)
    const token = useAppSelector((state) => state.auth.token)

    function openAuth() {
        // @ts-ignore
        dispatch(setAuthModal(true))
    }

    const listUser: any[] = [
        { value: 0, title: 'Профиль', ico: user_ico },
        { value: 1, title: 'Настройки', ico: settings },
        { value: 2, title: 'Выход', ico: logout },
    ]

    return (
        <header className={'header'}>
            <div className='container header__top-wrapper'>
                <div className='header__top'>
                    <Link href={APP_ROUTES.MAIN}>
                        <picture>
                            <source
                                width={192}
                                height={32}
                                media='(max-width: 360px)'
                                srcSet='/img/logotype.svg'
                            />
                            <img
                                height={32}
                                width={208}
                                className='main-logo'
                                src='/img/logo_new.svg'
                                alt='OddsOcean logo'
                            />
                        </picture>
                    </Link>
                    <div className='header__center-section'>
                        <div className='menu menu--top menu--white flex grid grid--40'>
                            <div className='menu__item menu__item--active'>
                                <a href='' className='menu__link'>
                                    {t('header.bets')}
                                </a>
                            </div>
                            <div className='menu__item'>
                                <span className='menu__title'>
                                    {t('global.betting_contest')}
                                </span>
                                <span className='badge badge--gray space-4'>
                                    3 449 ¢
                                </span>
                            </div>
                            <div className='menu__item'>
                                <span className='menu__title'>
                                    {t('header.betting_world')}
                                </span>
                                <Image
                                    src={arrow_down}
                                    alt='ava'
                                    className='menu__arrow'
                                    width={13}
                                    height={13}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='header__right-section grid grid--16 flex-align header__menu-tools-user'>
                        <div className='flex grid grid--8 header__menu-tools'>
                            <LanguageSwitcher />
                            <MenuMobile />
                            <NoSSR>
                                {(!user || !token) && (
                                    <button
                                        onClick={openAuth}
                                        className={'button button--bound button--small-size button--transparent-color show-mobile'}
                                    >
                                        <Image src={user_login} alt='global' />
                                    </button>
                                )}
                            </NoSSR>
                        </div>
                        <NoSSR>
                            {user && token ? (
                                <Dropdown
                                    onChange={(e: any) => {
                                        if (e.value == 2) {
                                            dispatch(postLogout())
                                        }
                                    }}
                                    classNameList='sports-list-dropdown sports-list-dropdown--user'
                                    header={
                                        <div
                                            className='header__avatar'
                                            style={{
                                                backgroundColor: !user.avatar[0]
                                                    ? '#' + AVATAR_COLOR[user.avatar[2]]
                                                    : 'transparent',
                                            }}
                                        >
                                            {user.avatar[0] && (
                                                <Image
                                                    src={`https://i.oddsocean.com/img/avatars/${user.avatar[0]}.jpg`}
                                                    alt='avatar'
                                                    width={32}
                                                    height={32}
                                                />
                                            )}
                                            {!user.avatar[0] &&
                                                user.avatar[1] && (
                                                <span className='rating-list__avatar-letter'>
                                                    {user.avatar[1]}
                                                </span>
                                            )}
                                        </div>
                                    }
                                    options={listUser}
                                />
                            ) : (
                                <>
                                    <button
                                        onClick={openAuth}
                                        className='button button--primary-color button--small-size button--rounded button--signin'
                                    >
                                        {t('header.signin')}
                                    </button>
                                </>
                            )}
                        </NoSSR>
                    </div>
                </div>
            </div>
            <div
                className={st.containerLeagueWrapper}
                ref={refMainContainer}
            >
                <Search />
                {competitionsTop?.length > 0 && (
                    <HeaderCompetitionLinks
                        competitionsTop={competitionsTop}
                        refOuterContainer={refMainContainer}
                    />
                )}
            </div>
        </header>
    )
}
