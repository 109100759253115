import Image from 'next/image'
import search from '/public/img/search.svg'
import closeIco from '/public/img/close.svg'
import st from './Search.module.scss'
import { Flex } from '../../ui'
import React, { useEffect, useRef, useState } from 'react'
import { SearchElement, SearchMethod, useSearchQuery } from '../../api'
import { useTranslation } from 'react-i18next'
import { SwitchButtons } from '../../components/switch-buttons'
import useOutsideAlerter from '../../hooks/outsideClick'
import { SearchItem, SearchItemInHistory } from './parts'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { appUserSelector } from '../../features/auth'
import { setSearchBlur } from '../../features/ui'
import { RemoveScroll } from 'react-remove-scroll'
import clsx from 'clsx'

const dropdownHeightInitial = 360

//@todo Закрытие списка на esc
export const Search = () => {
    const { i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const user = useAppSelector(appUserSelector)

    const refContainer = useRef<HTMLDivElement>(null)
    const refDropdownContent = useRef<HTMLDivElement>(null)
    const [query, setQuery] = useState('')
    const [tab, setTab] = useState<SearchMethod>('all')
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [dropdownHeight, setDropdownHeight] = useState(dropdownHeightInitial)

    function onSearchOpen() {
        setIsSearchActive(true)
        dispatch(setSearchBlur(true))
    }
    function onSearchClose() {
        setQuery('')
        setTab('all')
        setIsSearchActive(false)
        setDropdownHeight(dropdownHeightInitial)
        dispatch(setSearchBlur(false))
    }
    useOutsideAlerter(refContainer, () => onSearchClose())

    const queryDebounced = useDebounce(query, 700)
    const showSearchHistory = queryDebounced.length < 2

    const {data, isFetching} = useSearchQuery({
        query: showSearchHistory ? '' : queryDebounced,
        method: tab,
        locale: i18n.language,
    }, {
        skip: !isSearchActive,
        refetchOnMountOrArgChange: true,
    })

    useEffect(() => {
        if (isFetching) {
            setDropdownHeight(dropdownHeightInitial)
            return
        }
        setDropdownHeight(refDropdownContent.current?.clientHeight ?? dropdownHeightInitial)
    }, [isFetching])

    const searchResult = data?.data?.results ?? []
    const searchHistory: SearchElement[] = []
    const searchSuggestions: SearchElement[] = []
    if (showSearchHistory) {
        searchResult.forEach((item) => {
            if (item.type === 0) searchHistory.push(item)
            if (item.type === 1) searchSuggestions.push(item)
        })
    }

    const tabsArray: {id: SearchMethod, label: string}[] = [
        {
            id: 'all',
            label: 'все',
        },
        {
            id: 'participant',
            label: 'команды',
        },
        {
            id: 'competition',
            label: 'турниры',
        },
        {
            id: 'match',
            label: 'матчи',
        },
        {
            id: 'bookmaker',
            label: 'букмекеры',
        },
    ]

    return (
        <div
            className={st.base}
            ref={refContainer}
        >
            <Flex
                className={st.inputWrapper}
                dir='row'
                align='center'
                gap={8}
            >
                <Image
                    src={search}
                    alt='Icon search'
                />
                <label
                    htmlFor='search'
                    className={st.inputLabel}
                />
                <input
                    id='search'
                    className={st.input}
                    type='search'
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onFocus={onSearchOpen}
                    placeholder='Найти игрока, команду, лигу ...'
                    autoComplete='off'
                />
                {query.length > 0 && (
                    <button
                        className={st.close}
                        onClick={() => setQuery('')}
                    >
                        <Image
                            src={closeIco}
                            width={16}
                            height={16}
                            alt='Icon close'
                        />
                    </button>
                )}
            </Flex>
            {isSearchActive && (
                <RemoveScroll>
                    <div
                        className={st.dropdown}
                        style={{
                            height: dropdownHeight + 'px',
                        }}
                    >
                        <Flex refOuter={refDropdownContent}>
                            <SwitchButtons
                                className={st.tabsContainer}
                                value={tab}
                                onChange={(v) => setTab(v as SearchMethod)}
                                options={tabsArray}
                                mode='primary'
                            />
                            {isFetching && (
                                <>
                                    <div className={st.loadPill} />
                                    <div className={st.loadPill} />
                                    <div className={st.loadPill} />
                                    <div className={st.loadPill} />
                                    <div className={st.loadPill} />
                                </>
                            )}
                            {!isFetching && (showSearchHistory ? (
                                <>
                                    {searchHistory.length > 0 && (
                                        <>
                                            <div className={st.sectionHeader}>
                                                История поиска
                                            </div>
                                            {searchHistory.map((item) => (
                                                <SearchItemInHistory
                                                    key={item.id}
                                                    item={item}
                                                    onClick={onSearchClose}
                                                />
                                            ))}
                                        </>
                                    )}
                                    {searchSuggestions.length > 0 && (
                                        <>
                                            <div className={st.sectionHeader}>
                                                предложенные
                                            </div>
                                            {searchSuggestions.map((item) => (
                                                <SearchItem
                                                    key={item.id}
                                                    item={item}
                                                    onClick={onSearchClose}
                                                    isUser={!!user}
                                                />
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : searchResult.length > 0 ? (
                                <>
                                    {searchResult.map((item) => (
                                        <SearchItem
                                            key={item.id}
                                            item={item}
                                            onClick={onSearchClose}
                                            isUser={!!user}
                                        />
                                    ))}
                                </>
                            ) : (
                                <Flex
                                    className={st.notFound}
                                    align='center'
                                    justify='center'
                                    grow={1}
                                >
                                    Ничего не найдено
                                </Flex>
                            ))}
                        </Flex>
                    </div>
                </RemoveScroll>
            )}
        </div>
    )
}

function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}