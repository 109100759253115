import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from './baseApi'
import { HYDRATE } from 'next-redux-wrapper'
import { IFetchSuccess, SearchElementSaveParams, SearchFetched, SearchParams } from './types'

export const searchApi = createApi({
    reducerPath: 'search',
    baseQuery: axiosBaseQuery({
        baseUrl: 'https://api.oddsocean.com/v1/',
    }),
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }
    },
    tagTypes: [],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        search: builder.query<IFetchSuccess<SearchFetched>, SearchParams>({
            query: ({ query, method, locale }) => ({
                url: `search?language=${locale}`,
                method: 'post',
                data: {
                    query,
                    method,
                },
            }),
        }),
        removeHistoryItem: builder.query<IFetchSuccess<void>, SearchElementSaveParams>({
            query: ({ id, method }) => ({
                url: 'searchhistory',
                method: 'delete',
                data: {
                    id,
                    method,
                },
            }),
        }),
    }),
})

export const {
    useSearchQuery,
    useLazyRemoveHistoryItemQuery,
} = searchApi