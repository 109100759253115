import { SearchElement, useLazyRemoveHistoryItemQuery } from '../../../api'
import st from './SearchItem.module.scss'
import Image from 'next/image'
import { APP_IMAGES_URL } from '../../../components/logo/const'
import { Flex } from '../../../ui'
import iconClose from '../../../public/img/close_modal.svg'
import { getDateFormattedFromTimestamp } from '../../../utils'
import clsx from 'clsx'
import Link from 'next/link'
import { MouseEvent, ReactNode } from 'react'
import { Logo } from '../../../components/logo'

type Props = {
    item: SearchElement,
    onClick: () => void,
    isUser?: boolean,
    slotButtonCLose?: ReactNode,
    isRemoved?: boolean,
}

export const SearchItem = ({
    item,
    onClick,
    isUser,
    slotButtonCLose,
    isRemoved,
}: Props) => (
    // Для юзеров добавляем параметры сохранения истории выбранных элементов поиска
    <Link
        className={clsx(st.base, isRemoved && st.isRemoved)}
        href={isUser ? `${item.url}?historySearchId=${item.id}&method=${item.method}` : item.url}
        onClick={onClick}
        tabIndex={isRemoved ? -1 : 0}
    >
        <Flex
            key={item.id}
            dir='row'
            align='center'
            gap={12}
        >
            <Flex
                className={st.logoWrapper}
                align='center'
                justify='center'
                shrink={0}
            >
                {item.icons[0] && (item.method === 'match' ? (
                    <div className={st.matchLogos}>
                        <Image
                            src={APP_IMAGES_URL + item.icons[0]}
                            alt='Icon'
                            width={26}
                            height={26}
                        />
                        {item.icons[1] && (
                            <Image
                                src={APP_IMAGES_URL + item.icons[1]}
                                alt='Icon'
                                width={26}
                                height={26}
                            />
                        )}
                    </div>
                ) : item.method === 'bookmaker' ? (
                    <div
                        className={st.bookmakerLogo}
                        style={{
                            backgroundColor: `#${item.icons[1]}`,
                        }}
                    >
                        <Image
                            src={APP_IMAGES_URL + item.icons[0]}
                            alt='Icon'
                            width={40}
                            height={26}
                        />
                    </div>
                ) : (
                    <Image
                        src={APP_IMAGES_URL + item.icons[0]}
                        alt='Icon'
                        width={30}
                        height={30}
                    />
                ))}
            </Flex>
            <Flex
                className='overflow-hidden'
                gap={2}
                grow={1}
            >
                <span
                    className={st.title}
                    title={item.title}
                >
                    {item.title}
                </span>
                <Flex
                    dir='row'
                    gap={4}
                    align='center'
                >
                    {!!item.sport_id && (
                        <Logo
                            className='opacity-04'
                            type='sport'
                            dimension={14}
                            logoId={item.sport_id}
                        />
                    )}
                    {item.description && (
                        <span
                            className={clsx(st.description, 'text-with-overflow')}
                            title={item.description}
                        >
                            {item.description}
                        </span>
                    )}
                    {item.date_start && (
                        <>
                            <div className={st.textDivider} />
                            <span className={st.description}>
                                {getDateFormattedFromTimestamp(item.date_start, 'DD.MM')}
                            </span>
                        </>
                    )}
                </Flex>
            </Flex>
            <Flex
                className={st.addonAfter}
                dir='row'
                gap={4}
            >
                {item.status && (
                    <div className={clsx(st.badge, item.live && st.badgeLive)}>
                        {item.status}
                    </div>
                )}
                {slotButtonCLose}
            </Flex>
        </Flex>
    </Link>
)

export const SearchItemInHistory = (props: Props) => {
    const [trigger, { isFetching, isSuccess }] = useLazyRemoveHistoryItemQuery()

    function removeFromHistory(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        e.stopPropagation()
        trigger({
            id: props.item.id,
            method: props.item.method,
        })
    }

    return (
        <SearchItem
            {...props}
            isRemoved={isSuccess}
            slotButtonCLose={(
                <button
                    className={clsx(st.buttonRemove, 'flex flex-center flex-align')}
                    onClick={removeFromHistory}
                    disabled={isFetching}
                    tabIndex={isSuccess ? -1 : 0}
                >
                    <Image
                        src={iconClose}
                        alt='Icon close'
                        width={16}
                        height={16}
                    />
                </button>
            )}
        />
    )
}