import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { appReducer } from './features/app'
import { virtulBookmakerReducer } from './features/virtual-bookmaker'
import { competitionsReducer } from './features/competitions'
import { matchesReducer } from './features/matches'
import { surebetsReducer } from './features/surebets'
import { bookmakersReducer } from './features/bookmakers'
import { authReducer } from './features/auth'
import { competitionsApi } from './api/competitionsApi'
import { resultsTeamReducer } from './features/results-team'
import { filtersReducer } from './features/filters';
import { matchApi, matchesApi, searchApi } from './api'
import { reducerMatch } from './features/match'
import { reducerUI } from './features/ui'

export const makeStore = () =>
    configureStore({
        reducer: {
            app: appReducer,
            [matchesApi.reducerPath]: matchesApi.reducer,
            [matchApi.reducerPath]: matchApi.reducer,
            [competitionsApi.reducerPath]: competitionsApi.reducer,
            [searchApi.reducerPath]: searchApi.reducer,
            virtualBookmaker: virtulBookmakerReducer,
            competitions: competitionsReducer,
            matches: matchesReducer,
            surebets: surebetsReducer,
            bookmakers: bookmakersReducer,
            auth: authReducer,
            resultsTeam: resultsTeamReducer,
            filters: filtersReducer,
            match: reducerMatch,
            ui: reducerUI,
        },
        middleware: (gDM) =>
            gDM()
                .concat(matchesApi.middleware)
                .concat(matchApi.middleware)
                .concat(competitionsApi.middleware)
                .concat(searchApi.middleware),
    })

export type AppStore = ReturnType<typeof makeStore>
export type AppDispatch = AppStore['dispatch']
export type RootState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false })
