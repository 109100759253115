import type { AxiosRequestConfig, AxiosError } from 'axios'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios from 'axios'
import Cookies from 'js-cookie'

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
            extraData?: any
        },
        unknown,
        unknown
    > =>
        async ({ url, method, data, params }) => {
            const headers = {
                'Accept-Encoding': 'gzip,deflate,compress',
                'X-Timezone-Offset': String(-new Date().getTimezoneOffset()),
                'X-Auth-Key' : Cookies.get('auth-token'),
            }

            try {
                const result = await axios({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers,
                })
                return { data: result.data }
            } catch (axiosError) {
                const err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }
