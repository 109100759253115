import clsx from 'clsx'
import { FU } from '../../types'
import st from './Flex.module.scss';
import { RefObject } from 'react'

type SpacerAlign = 'start' | 'center' | 'end' | 'stretch';
type SpacerGap = 2 | 4 | 8 | 12 | 16 | 32;

type Props = {
    className?: string,
    dir?: 'row' | 'col',
    wrap?: boolean,
    align?: SpacerAlign,
    justify?: 'start' | 'center' | 'between' | 'end',
    gap?: SpacerGap,
    shrink?: 0,
    grow?: 1,
    refOuter?: RefObject<HTMLDivElement>,
}

export const Flex: FU<Props> = ({
    className,
    children,
    dir = 'col',
    wrap,
    justify,
    align,
    gap,
    shrink,
    grow,
    refOuter,
}) => {
    return (
        <div
            className={clsx(
                st.base,
                dir === 'row' ? st.dirRow : st.dirCol,
                wrap && st.wrap,
                justify && st[`justify_${justify}`],
                align && st[`align_${align}`],
                gap && st[`gap_${gap}`],
                shrink !== undefined && st[`shrink_${shrink}`],
                grow !== undefined && st[`grow_${grow}`],
                className,
            )}
            ref={refOuter}
        >
            {children}
        </div>
    );
};