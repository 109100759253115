import {LogoSize, LogoType} from './types';
import {APP_IMAGES_URL} from './const';

export function getLogoUrl(type: LogoType, size: LogoSize, logoId: number | string) {
    if (type === 'teams') {
        return `${APP_IMAGES_URL}/img/logos/teams/${size}x${size}/${logoId}.png`
    }

    if (type === 'country') {
        return `${APP_IMAGES_URL}/img/logos/teams/${size}x${size}/flag${logoId}.png`
    }

    if (type === 'bookmaker_original') {
        return `${APP_IMAGES_URL}/img/logos/bookmakers/svg/${logoId}_original.svg`
    }

    if (type === 'bookmaker_white') {
        return `${APP_IMAGES_URL}/img/logos/bookmakers/svg/${logoId}_white.svg`
    }

    if (type === 'sport') {
        return `${APP_IMAGES_URL}/img/sport/dark/${logoId}.svg`
    }

    return ''
}